var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoice-container" },
    [
      _c(
        "div",
        {
          staticClass: "invoice",
          class: {
            waiting: _vm.invoice.status === 1,
          },
          on: { click: _vm.handleClickInvoice },
        },
        [
          _vm.invoice_status
            ? _c(
                "el-tooltip",
                { attrs: { placement: "top", content: _vm.icon_tooltip } },
                [
                  _c("invoice-status-icon", {
                    staticClass: "icon",
                    attrs: { status: _vm.invoice_status, due: _vm.due_status },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticClass: "sequence" }, [
            _vm._v(" Invoice "),
            _c("strong", [
              _vm._v(
                _vm._s(_vm.invoice.sequence) + "/" + _vm._s(_vm.invoices_count)
              ),
            ]),
          ]),
          _c("hr"),
          _c(
            "span",
            {
              staticClass: "reference",
              class: { unscheduled: _vm.unscheduled && !_vm.sent },
            },
            [
              _vm.invoice.xero
                ? _c("span", { staticClass: "number" }, [
                    _vm._v(" " + _vm._s(_vm.invoice.xero.invoice_number) + " "),
                  ])
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(_vm.invoice.reference ?? _vm.reference_preview) +
                  " "
              ),
            ]
          ),
          _c("hr"),
          _c(
            "span",
            { staticClass: "status" },
            [
              _vm._v(" " + _vm._s(_vm._f("currency")(_vm.invoice.total)) + " "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "GST included",
                    placement: "top",
                    "open-delay": 500,
                  },
                },
                [
                  _vm.invoice.is_gst_included
                    ? _c("i", { staticClass: "el-icon-warning" })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("arrow-spacer", {
        class: { hidden: !_vm.consecutive },
        attrs: { size: "small" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }